import React from "react";
import { Link } from "react-router-dom";

import ErrorBG from "../../assets/images/error_bg.svg";

const Error503 = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="content d-flex justify-content-center align-items-center">
              <div className="flex-fill">
                <div className="text-center mb-4">
                  <img
                    src={ErrorBG}
                    className="img-fluid mb-3"
                    height="230"
                    alt=""
                  />
                  <h1 className="display-3 fw-semibold lh-1 mb-3">503</h1>
                  <h5 className="w-md-25 mx-md-auto">
                    Oops, an error has occurred. The server encountered an
                    internal error and was unable to complete your request.
                  </h5>
                </div>

                <div className="text-center">
                  <Link to="/dashboard" className="btn btn-primary">
                    <i className="ph-house me-2"></i>
                    Return to dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Error503;
