import React from "react";
import { Link } from "react-router-dom";

const NavbarHeader = () => {
  return (
    <React.Fragment>
      <div className="navbar header-navbar border-bottom border-top py-1">
        <div className="container-fluid">
          <ul className="nav align-items-center">
            <li className="nav-item">
              <Link
                to="#"
                className="navbar-nav-link navbar-nav-link-icon rounded"
              >
                <div className="d-flex align-items-center mx-md-1">
                  <i className="ph-git-branch"></i>
                  <span className="d-none d-md-inline-block ms-2">
                    Branches
                  </span>
                </div>
              </Link>
            </li>
            <li className="nav-item ms-1">
              <Link
                to="#"
                className="navbar-nav-link navbar-nav-link-icon rounded"
              >
                <div className="d-flex align-items-center mx-md-1">
                  <i className="ph-git-merge"></i>
                  <span className="d-none d-md-inline-block ms-2">Merges</span>
                  <span className="badge bg-yellow text-black rounded-pill ms-1 ms-md-2">
                    5
                  </span>
                </div>
              </Link>
            </li>
            <li className="nav-item ms-1">
              <Link
                to="#"
                className="navbar-nav-link navbar-nav-link-icon rounded"
              >
                <div className="d-flex align-items-center mx-md-1">
                  <i className="ph-git-pull-request"></i>
                  <span className="d-none d-md-inline-block ms-2">
                    Pull Requests
                  </span>
                </div>
              </Link>
            </li>
          </ul>

          <ul className="nav align-items-center">
            <li className="nav-item">
              <Link
                to="#"
                className="navbar-nav-link navbar-nav-link-icon rounded"
              >
                <div className="d-flex align-items-center mx-md-1">
                  <i className="ph-git-fork"></i>
                  <span className="d-none d-md-inline-block ms-2">
                    Repositories
                  </span>
                  <span className="badge bg-secondary rounded-pill ms-1 ms-md-2">
                    28
                  </span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavbarHeader;
