import React, { useState, useEffect } from "react";
import useFetch from "../../customHooks/useFetch";
import { useLocation } from "react-router-dom";
import { convertFromJson } from "fiql-query-builder";
import { useOidcUser, useOidc } from "@axa-fr/react-oidc";
import { toast } from "react-toastify";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import Create from "./create";
import Edit from "./edit";
import Delete from "./delete";
import CompaniesService from "../../services/registry-service/companies";
import {
  getUniqueSelectors,
  writeLabelAddress,
  buildQuerySort,
} from "../../utilities";

const companiesService = new CompaniesService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  id: null,
  action: "",
};

const defaultQuerySearch = [
  {
    equals: {
      selector: "myAddress",
      args: "true",
    },
  },
];

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Addresses = () => {
  const navigate = useNavigate();

  const query = useQuery();
  const { oidcUser } = useOidcUser();
  const { logout } = useOidc();
  const [queryParams, setQueryParams] = useState([
    ...defaultQuerySearch,
    {
      equals: {
        selector: "ownerId",
        args: query.get("companyId"),
      },
    },
  ]);
  const [querySearch, setQuerySearch] = useState();
  const [company, setCompany] = useState({});
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);

  const columns = [
    {
      Header: "Ragione sociale",
      accessor: "name",
      sortable: true,
      filter: {
        active: true,
        name: "name",
        type: "search",
      },
    },
    {
      Header: "Nome",
      accessor: "contactName",
      sortable: true,
      filter: {
        active: true,
        name: "contactName",
        type: "search",
      },
    },
    {
      Header: "Indirizzo",
      accessor: "location",
      Cell: ({ row, cell }) => {
        return writeLabelAddress(cell.value);
      },
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Telefono",
      accessor: "phone",
    },
    // {
    //   Header: "Cellulare",
    //   accessor: "mobilePhone",
    // },
    {
      Header: "Privato",
      accessor: "priv",
      Cell: ({ row }) => {
        return row.original.priv ? "SI" : "NO";
      },
    },
    {
      Header: "GDO",
      accessor: "gdo",
      Cell: ({ row }) => {
        return row.original.gdo ? "SI" : "NO";
      },
    },
    {
      Header: "ZTL",
      accessor: "ztl",
      Cell: ({ row }) => {
        return row.original.ztl ? "SI" : "NO";
      },
    },
    {
      Header: "Azioni",
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        const { values } = row;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              <DropdownItem
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Modifica Indirizzo",
                    icon: "ph-note-pencil",
                    open: !modalSettings.open,
                    action: "edit",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-note-pencil me-2"></i>
                Modifica
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Elimina Cliente",
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    action: "delete",
                    id: [values.id],
                  });
                }}
              >
                <i className="ph-trash me-2"></i>
                Elimina
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const getCompany = () => {
    companiesService
      .getCompany(query.get("companyId"))
      .then((response) => {
        setCompany(response);
      })
      .catch((exception) => {
        toast.error(
          "Si sono verificati dei problemi nel recupero del cliente",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      });
  };

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const buildQuerySearch = (query = []) => {
    return encodeURIComponent(convertFromJson({ and: [...query] }));
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/registry-service/address-book?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }`
  );

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title="Indirizzi"
          breadcrumbItem={company?.name}
          link={`?companyId=${query.get("companyId")}`}
        />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                {/* {query.get("companyId") && (
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-light btn-sm"
                      onClick={() => {
                        navigate(`/shipments`);
                      }}
                    >
                      <i className="ph-arrow-left ph-sm me-2"></i>Torna indietro
                    </button>
                  </div>
                )} */}
              </div>
              <div className="col">
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Nuovo Indirizzo",
                      icon: "ph-plus",
                      open: !modalSettings.open,
                      action: "create",
                    })
                  }
                >
                  Nuovo Indirizzo <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>
            {oidcUser && (
              <TableAdvanced
                hasFilter={true}
                columns={columns}
                data={data}
                className="custom-header-css"
                tableClass={"table-xxs"}
                paginationOpt={pagination}
                onPaginationChange={handlePagination}
                sortingOpt={sort}
                onSortingChange={handleSorting}
                refreshTable={refreshData}
                onSearch={onSearch}
                onFreeSearch={onSearch}
                showLoader={loading}
              />
            )}

            <Modal
              isOpen={modalSettings.open}
              toggle={modalToggle}
              className="modal-content"
            >
              <ModalHeader toggle={modalToggle}>
                <span className="modal-title">
                  <i className={modalSettings.icon + " me-2"}></i>
                  {modalSettings.title}
                </span>
              </ModalHeader>
              <ModalBody>
                {modalSettings.action === "create" && (
                  <Create
                    params={{
                      companyId: company?.id || oidcUser.owner,
                      myAddress: true,
                    }}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {modalSettings.action === "edit" && (
                  <Edit
                    addressId={modalSettings.id}
                    params={{
                      companyId: company?.id || oidcUser.owner,
                      myAddress: true,
                    }}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {/* {modalSettings.action === "clone" && (
                  <Edit
                    userOwnerId={oidcUser.owner}
                    companyId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                    clone={true}
                  />
                )} */}
                {modalSettings.action === "delete" && (
                  <Delete
                    addressBookId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Addresses;
