import React, { useEffect } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { changeLayoutMode } from "../store/actions";
import withRouter from "../Components/common/withRouter";
import HeaderTopNotAuth from "./VerticalLayouts/HeaderTopNotAuth";
import { useAppContext } from "../AppProvider";

const NonAuthLayout = ({ header, children }) => {
  const dispatch = useDispatch();
  const selectLayoutProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      layoutModeType: layout.layoutModeType,
    })
  );

  const { layoutModeType } = useSelector(selectLayoutProperties);

  const appContext = useAppContext();

  useEffect(() => {
    if (layoutModeType) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);
  return React.Children.map(children, (child) => {
    return (
      <React.Fragment>
        {header && <HeaderTopNotAuth />}
        {React.cloneElement(child)}
        {/* <Footer /> */}
      </React.Fragment>
    );
  });
};
export default withRouter(NonAuthLayout);
