import React, { useState, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  buildQuerySort,
  buildQuerySearch,
  modalSettingsDefault,
  getUniqueSelectors,
  orderBykey,
  callErrorToast,
  getClassForMultiActionElement,
} from "../../utilities";
import {
  allSelectedCollectionCanBeDeleted,
  allSelectedCollectionsCanBeDeleted,
  canAllCollectionsBeDeleted,
  columns,
} from "./utilities";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import useFetch from "../../customHooks/useFetch";
import { ContractsService } from "../../services/contract/contracts";
import CreateEdit from "./CreateEdit";
import Delete from "./Delete";
import Book from "./Book";
import Details from "./Details";

const Collections = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "desc",
      column: "date",
    },
  ]);
  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [statusOfTableRows, setStatusOfTableRows] = useState([]);

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/shipment-service/collections?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  const onChangeStatusRow = (changeStatusForRows) => {
    setStatusOfTableRows((previousStatusOfTableRows) => {
      changeStatusForRows.forEach((changedRow) => {
        const existingRow = previousStatusOfTableRows.find(
          (row) => row.id === changedRow.id
        );
        if (existingRow) {
          existingRow.value = changedRow.value;
        } else {
          previousStatusOfTableRows.push(changedRow);
        }
      });
      return [...previousStatusOfTableRows];
    });
  };

  const onChangeStatusAllRows = (val) => {
    const newSelectedRow = data.content.map((x) => {
      return { id: x.id, value: val };
    });
    setStatusOfTableRows(newSelectedRow);
  };

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  const allColumns = orderBykey(
    [
      ...columns,
      {
        Header: (
          <>
            <div
              className={
                "multi-action-label scale-animation " +
                getClassForMultiActionElement(statusOfTableRows).labelClass
              }
            >
              Azioni
            </div>
            <UncontrolledDropdown
              className={
                getClassForMultiActionElement(statusOfTableRows).dropDownClass
              }
            >
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className={"btn border-0 text-body"}
                data-bs-toggle="dropdown"
              >
                <i
                  className={
                    "ph-fill ph-list text-primary fw-bolder multi-action-icon scale-animation " +
                    getClassForMultiActionElement(statusOfTableRows).iconClass
                  }
                ></i>
              </DropdownToggle>
              <DropdownMenu className={"dropdown-menu-end "} container={"body"}>
                <DropdownItem
                  href="#"
                  className={
                    allSelectedCollectionsCanBeDeleted(
                      statusOfTableRows,
                      data.content
                    )
                      ? "text-danger"
                      : "disabled"
                  }
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Elimina ritiri selezionati",
                      icon: "ph-trash",
                      open: !modalSettings.open,
                      collections: data.content.filter((collection) =>
                        statusOfTableRows
                          .filter((element) => element.value)
                          .map((x) => x.id)
                          .includes(collection.id)
                      ),
                      action: "delete",
                    })
                  }
                >
                  <i className="ph-trash me-2"></i>
                  Elimina selezionate
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
        pinning: "right",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className="btn border-0 text-body"
                data-bs-toggle="dropdown"
              >
                <i className="ph-list"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                {original.status === "DRAFT" && (
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: "Modifica ritiro",
                        icon: "ph-note-pencil",
                        open: !modalSettings.open,
                        action: "edit",
                        id: original.id,
                        carrierCode: original.carrierCode,
                        addressBookId: original.addressBook?.id,
                      });
                    }}
                  >
                    <i className="ph-note-pencil me-2"></i>
                    Modifica
                  </DropdownItem>
                )}
                {original.status === "DRAFT" && (
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: "Vuoi prenotare un ritiro?",
                        icon: "ph-calendar-check",
                        open: !modalSettings.open,
                        id: original.id,
                        action: "book",
                      });
                    }}
                  >
                    <i className="ph-calendar-check me-2"></i>
                    Prenota
                  </DropdownItem>
                )}
                <DropdownItem
                  href="#"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Dettaglio ritiro",
                      icon: "ph-magnifying-glass",
                      open: !modalSettings.open,
                      action: "detail",
                      id: row.original.id,
                    });
                  }}
                >
                  <i className="ph-magnifying-glass me-2"></i>
                  Dettaglio
                </DropdownItem>
                {original.status === "DRAFT" && (
                  <DropdownItem
                    className="text-danger"
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: "Elimina ritiro",
                        icon: "ph-trash",
                        open: !modalSettings.open,
                        action: "delete",
                        collections: [{ id: row.original.id }],
                      });
                    }}
                  >
                    <i className="ph-trash me-2"></i>
                    Elimina
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    "position"
  );

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Ritiri" breadcrumbItem="Elenco ritiri" />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Nuovo ritiro",
                      icon: "ph-plus",
                      action: "create",
                      open: !modalSettings.open,
                    })
                  }
                >
                  Nuovo ritiro <i className="ph-plus ms-2"></i>
                </button>
              </div>
            </div>
            <TableAdvanced
              selectMultipleRows={true}
              statusOfTableRows={statusOfTableRows}
              onChangeStatusRow={onChangeStatusRow}
              onChangeStatusAllRows={onChangeStatusAllRows}
              hasFilter={true}
              columns={allColumns}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              omnisearch={{
                active: false,
                onSearch: setFreeSearch,
                placeholder: "Nome",
              }}
              showLoader={loading}
            />
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modalSettings.open}
        toggle={modalToggle}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "create" && (
            <CreateEdit unmountCallback={modalToggle} standAlone={true} />
          )}
          {modalSettings.action === "edit" && (
            <CreateEdit
              id={modalSettings.id}
              carrierCode={modalSettings.carrierCode}
              unmountCallback={modalToggle}
              defaultContactId={modalSettings.addressBookId}
            />
          )}
          {modalSettings.action === "detail" && (
            <Details id={modalSettings.id} />
          )}
          {modalSettings.action === "delete" && (
            <Delete
              collections={modalSettings.collections}
              unmountCallback={modalToggle}
            />
          )}
          {modalSettings.action === "book" && (
            <Book id={modalSettings.id} unmountCallback={modalToggle} />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Collections;
