
//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_REGISTER = "/auth/signup";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";
