import { combineReducers } from "redux";

// Layout
import Layout from "./layouts/reducer";

// Login
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/ForgetPassword/reducer";

//Calendar
import Calendar from "./calendar/reducer";
const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  Calendar,
  ForgetPassword
});

export default rootReducer;
