import React, { useState, useEffect } from "react";
import useFetch from "../../customHooks/useFetch";
import BreadcrumbItem from "../../Components/common/Breadcrumb";

import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import Delete from "./delete";
import Confirm from "./confirm";
import DeleteAll from "./deleteAll";
import ConfirmAll from "./confirmAll";
import Print from "./Print";
import {
  getUniqueSelectors,
  buildQuerySearch,
  buildQuerySort,
  callErrorToast,
  orderBykey,
} from "../../utilities";
import {
  allSelectedShipmentsCanBeClosed,
  allSelectedShipmentsCanBeCollect,
  allSelectedShipmentsCanBeDeleted,
  allSelectedShipmentsCanBePrinted,
  columns,
  defaultQuerySearch,
  getColumns,
} from "./util";
import { getClassForMultiActionElement } from "../../utilities/index";
import { ShipmentsService } from "../../services/shipment/shipments";
import CreateEdit from "../Collections/CreateEdit";
import { useOidcUser } from "@axa-fr/react-oidc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const shipmentsService = new ShipmentsService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  action: "",
};

const Shipments = () => {
  const { oidcUser } = useOidcUser();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "desc",
      column: "creationDate",
    },
  ]);
  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState(defaultQuerySearch);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [statusOfTableRows, setStatusOfTableRows] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onChangeStatusRow = (changeStatusForRows) => {
    setStatusOfTableRows((previousStatusOfTableRows) => {
      changeStatusForRows.forEach((changedRow) => {
        const existingRow = previousStatusOfTableRows.find(
          (row) => row.id === changedRow.id
        );
        if (existingRow) {
          existingRow.value = changedRow.value;
        } else {
          previousStatusOfTableRows.push(changedRow);
        }
      });
      return [...previousStatusOfTableRows];
    });
  };

  const onChangeStatusAllRows = (val) => {
    const newSelectedRow = data.content.map((x) => {
      return { id: x.id, value: val };
    });
    setStatusOfTableRows(newSelectedRow);
  };

  const onExport = (type) => {
    setExportLoader(true);
    switch (type) {
      case "csv":
        shipmentsService
          .exportCSV(querySearch, freeSearch)
          .then((response) => {
            const blob = new Blob([response.data], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "shipments_export_" + Date.now();
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setExportLoader(false);
          })
          .catch((err) => {
            callErrorToast(err);
            setExportLoader(false);
          });
        break;
    }
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/shipment-service/shipments?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
    setStatusOfTableRows([]);
  }, [queryParams, querySearch, pagination, sort]);

  const allColumns = orderBykey(
    [
      ...getColumns(),
      {
        position: 16,
        pinning: "right",
        Header: (
          <>
            <div
              className={
                "multi-action-label scale-animation " +
                getClassForMultiActionElement(statusOfTableRows).labelClass
              }
            >
              Azioni
            </div>

            <UncontrolledDropdown
              className={
                getClassForMultiActionElement(statusOfTableRows).dropDownClass
              }
            >
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className={"btn border-0 text-body"}
                data-bs-toggle="dropdown"
              >
                <i
                  className={
                    "ph-fill ph-list text-primary fw-bolder multi-action-icon scale-animation " +
                    getClassForMultiActionElement(statusOfTableRows).iconClass
                  }
                ></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                <DropdownItem
                  href="#"
                  className={
                    allSelectedShipmentsCanBeClosed(
                      data?.content,
                      statusOfTableRows
                    )
                      ? ""
                      : "disabled"
                  }
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Conferma",
                      icon: "ph-check",
                      open: !modalSettings.open,
                      shipments: data.content.filter((shipment) =>
                        statusOfTableRows
                          .filter((element) => element.value)
                          .map((x) => x.id)
                          .includes(shipment.id)
                      ),
                      action: "confirmAll",
                    })
                  }
                >
                  <i className="ph-check me-2"></i>
                  Conferma
                </DropdownItem>
                <DropdownItem
                  href="#"
                  className={
                    allSelectedShipmentsCanBeCollect(
                      data?.content,
                      statusOfTableRows
                    )
                      ? ""
                      : "disabled"
                  }
                  onClick={() => {
                    let selectedShipments = data.content.filter((shipment) =>
                      statusOfTableRows
                        .filter((element) => element.value)
                        .map((x) => x.id)
                        .includes(shipment.id)
                    );

                    const checkSelectedShipments = selectedShipments.every(
                      (shipment) =>
                        shipment.carrierCode ===
                          selectedShipments[0].carrierCode &&
                        (shipment.statusCode === "BOOKED" ||
                          shipment.statusCode === "CONFIRMED") &&
                        shipment.collectionCreationEnabled &&
                        !shipment.collectionAssigned
                    );
                    if (checkSelectedShipments) {
                      setModalSettings({
                        ...modalSettings,
                        title: "Prenota un ritiro",
                        icon: "ph-calendar-check",
                        open: !modalSettings.open,
                        carrierCode: selectedShipments[0].carrierCode,
                        pickupId: selectedShipments[0].pickupId,
                        shipmentIds: selectedShipments.map((x) => x.id),
                        action: "createCollection",
                      });
                    }
                  }}
                >
                  <i className="ph-calendar-check me-2"></i>
                  Prenota un ritiro
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Stampa etichette",
                      icon: "ph-download",
                      open: !modalSettings.open,
                      shipments: data.content.filter(
                        (shipment) =>
                          shipment.statusCode !== "DRAFT" &&
                          statusOfTableRows
                            .filter((element) => element.value)
                            .map((x) => x.id)
                            .includes(shipment.id)
                      ),
                      action: "print",
                    })
                  }
                >
                  <i className="ph-download me-2"></i>
                  Stampa etichette
                </DropdownItem>
                <DropdownItem
                  href="#"
                  className={
                    allSelectedShipmentsCanBeDeleted(
                      data?.content,
                      statusOfTableRows
                    )
                      ? "text-danger"
                      : "disabled"
                  }
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Elimina",
                      icon: "ph-check",
                      open: !modalSettings.open,
                      shipments: data.content.filter((shipment) =>
                        statusOfTableRows
                          .filter((element) => element.value)
                          .map((x) => x.id)
                          .includes(shipment.id)
                      ),
                      action: "deleteAll",
                    })
                  }
                >
                  <i className="ph-trash me-2"></i>
                  Elimina selezionate
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
        accessor: "id",
        Cell: ({ row }) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className="btn border-0 text-body"
                data-bs-toggle="dropdown"
              >
                <i className="ph-list"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container="body">
                {row.original.statusCode === "BOOKED" && (
                  <DropdownItem
                    href="#"
                    onClick={() =>
                      setModalSettings({
                        ...modalSettings,
                        title: "Conferma",
                        icon: "ph-check",
                        open: !modalSettings.open,
                        id: row.original.id,
                        action: "confirm",
                      })
                    }
                  >
                    <i className="ph-check me-2"></i>
                    Conferma
                  </DropdownItem>
                )}
                {(row.original.statusCode === "BOOKED" ||
                  row.original.statusCode === "CONFIRMED") &&
                  row.original.collectionCreationEnabled &&
                  !row.original.collectionAssigned && (
                    <DropdownItem
                      href="#"
                      onClick={() =>
                        setModalSettings({
                          ...modalSettings,
                          title: "Prenota un ritiro",
                          icon: "ph-calendar-check",
                          open: !modalSettings.open,
                          shipmentIds: [row.original.id],
                          pickupId: row.original.pickupId,
                          carrierCode: row.original.carrierCode,
                          action: "createCollection",
                        })
                      }
                    >
                      <i className="ph-calendar-check me-2"></i>
                      Prenota un ritiro
                    </DropdownItem>
                  )}
                {row.original.statusCode === "DRAFT" && (
                  <DropdownItem href={`/shipments/edit/${row.original.id}`}>
                    <i className="ph-note-pencil me-2"></i>
                    Modifica
                  </DropdownItem>
                )}
                <DropdownItem href={`/shipments/clone/${row.original.id}`}>
                  <i className="ph-users me-2"></i>
                  Duplica
                </DropdownItem>
                {row.original.shipmentType !== "EXPRESS_RETURN" && (
                  <DropdownItem href={`/shipments/return/${row.original.id}`}>
                    <FontAwesomeIcon icon={faExchangeAlt} className="me-2" />
                    Reso
                  </DropdownItem>
                )}
                <DropdownItem href={`/shipments/details/${row.original.id}`}>
                  <i className="ph-magnifying-glass me-2"></i>
                  Dettaglio
                </DropdownItem>
                {(row.original.statusCode === "CONFIRMED" ||
                  row.original.statusCode === "BOOKED") && (
                  <DropdownItem
                    href="#"
                    onClick={() =>
                      setModalSettings({
                        ...modalSettings,
                        title: "Stampa etichetta",
                        icon: "ph-download",
                        open: !modalSettings.open,
                        shipments: [{ id: row.original.id }],
                        action: "print",
                      })
                    }
                  >
                    <i className="ph-download me-2"></i>
                    Stampa etichetta
                  </DropdownItem>
                )}
                {(row.original.statusCode === "DRAFT" ||
                  row.original.statusCode === "BOOKED") && (
                  <DropdownItem
                    className="text-danger"
                    href="#"
                    onClick={() =>
                      setModalSettings({
                        ...modalSettings,
                        title: "Elimina",
                        icon: "ph-trash",
                        open: !modalSettings.open,
                        id: row.original.id,
                        action: "delete",
                      })
                    }
                  >
                    <i className="ph-trash me-2"></i>
                    Elimina
                  </DropdownItem>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    "position"
  );

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Spedizioni" breadcrumbItem="Elenco spedizioni" />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Link
                  to={"/shipments/create"}
                  className="nav-link float-end"
                  data-key="t-basic-action"
                >
                  <Button
                    color="light"
                    type="button"
                    className="btn btn-outline-success btn-sm"
                  >
                    Nuova spedizione <i className="ph-plus ms-2"></i>
                  </Button>
                </Link>
              </div>
            </div>

            <TableAdvanced
              selectMultipleRows={true}
              statusOfTableRows={statusOfTableRows}
              onChangeStatusRow={onChangeStatusRow}
              onChangeStatusAllRows={onChangeStatusAllRows}
              hasFilter={true}
              columns={allColumns}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              omnisearch={{
                active: true,
                onSearch: setFreeSearch,
                placeholder: "Riferimenti spedizione",
              }}
              toExport={{ onExport, types: ["csv"], loading: exportLoader }}
              showLoader={loading}
              myData={{ active: true, label: "Le mie spedizioni" }}
            />
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modalSettings.open}
        toggle={modalToggle}
        className="modal-content"
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action == "delete" && (
            <Delete
              id={modalSettings.id}
              callback={() => {
                modalToggle();
                refreshData();
              }}
            />
          )}
          {modalSettings.action == "confirm" && (
            <Confirm
              id={modalSettings.id}
              callback={() => {
                modalToggle();
                refreshData();
              }}
            />
          )}
          {modalSettings.action == "createCollection" && (
            <CreateEdit
              userOwnerId={oidcUser.owner}
              defaultContactId={modalSettings.pickupId}
              shipmentIds={modalSettings.shipmentIds}
              carrierCode={modalSettings.carrierCode}
              unmountCallback={() => {
                modalToggle();
                refreshData();
              }}
            />
          )}
          {modalSettings.action == "deleteAll" && (
            <DeleteAll
              shipments={modalSettings.shipments}
              modalToggle={modalToggle}
              refreshTable={refreshData}
            />
          )}
          {modalSettings.action == "confirmAll" && (
            <ConfirmAll
              shipments={modalSettings.shipments}
              modalToggle={modalToggle}
              refreshTable={refreshData}
            />
          )}
          {modalSettings.action == "print" && (
            <Print
              shipments={modalSettings.shipments}
              unmountCallback={() => {
                refreshData();
                modalToggle();
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Shipments;
