import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { OidcProvider, TokenRenewMode } from "@axa-fr/react-oidc";
import API from "./helpers/API";
import Error503 from "./pages/ErrorPages/Error503";
import { LoaderAllaboxIcon } from "./Components/common/LoaderAllaboxIcon";
import { AppProvider } from "./AppProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
const api = new API();
// const configuration = {
//   client_id: 'interactive.public.short',
//   redirect_uri: window.location.origin + '/authentication/callback',
//   silent_redirect_uri: window.location.origin + '/authentication/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
//   scope: 'openid profile',
//   authority: 'https://demo.duendesoftware.com',
//   service_worker_relative_url:'/OidcServiceWorker.js',
//   service_worker_only:true,
// };
const configuration = {
  client_id: "allabox",
  redirect_uri: window.location.origin + "/authentication/callback",
  silent_redirect_uri:
    window.location.origin + "/authentication/silent-callback",
  // silent_login_uri: window.location.origin + '/authentication/silent-login',
  // scope: 'openid profile email api offline_access',
  scope: "openid profile",
  authority: `https://auth.${api.getURL()}`,
  // authority_time_cache_wellknowurl_in_second: 60* 60,
  refresh_time_before_tokens_expiration_in_second: 40,
  service_worker_relative_url: "/OidcServiceWorker.js",
  service_worker_only: false,
  // storage: localStorage,
  // silent_login_timeout: 3333000
  // monitor_session: true,
  token_request_extras: {
    client_secret: "e21767fa-47cf-4932-9e84-75fa7b9b465e",
  },
  token_renew_mode: TokenRenewMode.access_token_invalid,
  // post_logout_redirect_uri: "/landing",
};

root.render(
  <Provider store={store}>
    <OidcProvider
      authenticatingErrorComponent={() => (
        <React.Fragment>
          <Error503 />
        </React.Fragment>
      )}
      authenticatingComponent={() => {
        <React.Fragment>
          <LoaderAllaboxIcon show={true} />
        </React.Fragment>;
      }}
      loadingComponent={() => (
        <React.Fragment>
          <LoaderAllaboxIcon show={true} />
        </React.Fragment>
      )}
      configuration={configuration}
      onSessionLost={() =>
        (window.location.href = "https://auth.allabox.it/login")
      }
    >
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AppProvider>
          <App />
        </AppProvider>
      </BrowserRouter>
    </OidcProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
