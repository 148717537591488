import React, { createContext, useState, useContext, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { BrandSettingsService } from "./services/registry/brandSettings";
import { CompaniesService } from "./services/registry/companies";

const brandSettingsService = new BrandSettingsService();
const companiesService = new CompaniesService();

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [brandSettings, setBrandSettings] = useState();
  const [owner, setOwner] = useState();

  const getBrandSettings = () => {
    brandSettingsService.getBrandSettingPublic().then((res) => {
      if (res.data) {
        setBrandSettings(res.data);
      }
    });
  };

  const getOwner = () => {
    companiesService.getCompany(accessTokenPayload?.owner).then((res) => {
      if (res.data) {
        setOwner(res.data);
      }
    });
  };

  useEffect(() => {
    getBrandSettings();
    if (accessTokenPayload) {
      getOwner();
    }
  }, []);

  return (
    <AppContext.Provider value={{ brandSettings, owner }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
