import React, { useState, useRef } from "react";
import debounce from "lodash.debounce";
import Select from "react-select";
import { FormFeedback } from "reactstrap";

const AsyncSelect = ({
  name,
  value,
  optionStyle,
  onSearch,
  loadOptions,
  isMulti = false,
  error = "",
  optionValue = "value",
  optionLabel = "label",
  isClearable = true,
  placeholder = "Seleziona...",
  isDisabled = false,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    isFirst: false,
    isLast: false,
  });

  const debounced = useRef(
    debounce(async (value, pagination, callback) => {
      callback(value, pagination).then((response) => {
        const resultArray = response.data
          ? Array.isArray(response.data)
            ? response.data
            : response.data.content || []
          : [];
        const result =
          resultArray?.map((elem) => {
            return {
              ...elem,
              value: elem.id || elem.code,
              label: elem.name,
            };
          }) || [];
        setOptions(result);
        setSearchValue(value);
        setPagination({
          ...pagination,
          isFirst: response.data?.last !== false ? true : false,
          isLast: response.data?.last !== false ? true : false,
        });
        setLoading(false);
      });
    }, 300)
  ).current;

  return (
    <React.Fragment>
      <Select
        maxMenuHeight={200}
        isMulti={isMulti}
        options={options}
        noOptionsMessage={() => <span>Nessun risultato trovato</span>}
        getOptionValue={(option) => option[optionValue]}
        getOptionLabel={(option) => option[optionLabel]}
        formatOptionLabel={(option) =>
          optionStyle
            ? optionStyle(option, optionValue, optionLabel)
            : option[optionLabel]
        }
        isClearable={isClearable}
        placeholder={placeholder}
        onChange={(value) => {
          onSearch(name, value, true);
        }}
        onInputChange={(inputValue, event) => {
          setLoading(true);
          if (event.action === "input-change") {
            debounced(
              inputValue,
              {
                ...pagination,
                page: 0,
              },
              loadOptions
            );
          }
        }}
        filterOption={() => true}
        onMenuOpen={() => {
          setLoading(true);
          loadOptions("", pagination).then((response) => {
            let result = [];
            if (response) {
              const resultArray = response?.data
                ? Array.isArray(response.data)
                  ? response.data
                  : response.data.content || []
                : [];
              result =
                resultArray?.map((elem) => {
                  return {
                    ...elem,
                    value: elem.id || elem.code,
                    label: elem.name,
                  };
                }) || [];

              setPagination({
                ...pagination,
                isFirst: response.data?.last !== false ? true : false,
                isLast: response.data?.last !== false ? true : false,
              });
            }

            setOptions(result);
            setLoading(false);
          });
        }}
        onMenuClose={() => {
          setPagination({
            page: 0,
            size: 10,
            isFirst: false,
            isLast: false,
          });
          setOptions([]);
          setSearchValue("");
          setLoading(false);
        }}
        onMenuScrollToBottom={() => {
          if (!pagination.isLast) {
            setLoading(true);
            const newPagination = {
              ...pagination,
              page: pagination.page + 1,
            };

            loadOptions(searchValue, newPagination).then((response) => {
              const resultArray = response.data
                ? Array.isArray(response.data)
                  ? response.data
                  : response.data.content || []
                : [];
              const result =
                resultArray?.map((elem) => {
                  return {
                    ...elem,
                    value: elem.id || elem.code,
                    label: elem.name,
                  };
                }) || [];
              setOptions([...options, ...result]);
              setPagination({
                ...newPagination,
                isFirst: response.data?.last !== false ? true : false,
                isLast: response.data?.last !== false ? true : false,
              });
              setLoading(false);
            });
          }
        }}
        isLoading={loading}
        className={`${error && "is-invalid"}`}
        value={value}
        isDisabled={isDisabled}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </React.Fragment>
  );
};

export default AsyncSelect;
