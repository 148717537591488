import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AuthSaga from "./auth/login/saga";
import AccountSaga from "./auth/register/saga";
import ForgetSaga from "./auth/ForgetPassword/saga";
import calendarSaga from "./calendar/saga";

export default function* rootSaga() {
  yield all([fork(LayoutSaga), fork(AuthSaga), fork(AccountSaga), fork(ForgetSaga), fork(calendarSaga)]);
}
