import moment from "moment";
import {
  getAllCarriers,
  getAllStores,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import {
  getCarrierOptionStyle,
  getIncomingGoodOptionStyle,
  getOrderStatusOptionStyle,
  getStoreOptionStyle,
  getWarehouseOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import { color } from "d3";
import {
  getIncomingGoodStatusBadge,
  incomingGoodStatus,
} from "../../utilities";

export const allSelectedIncomingGoodsCanBeDeleted = (
  incomingGoodSelectedIds = [],
  incomingGoods = []
) => {
  const idSelected = incomingGoodSelectedIds
    ?.filter((x) => x.value)
    .map((x) => x.id);
  const incomingGoodsSelected = incomingGoods.filter((x) =>
    idSelected.includes(x.id)
  );
  return canAllIncomingGoodsBeDeleted(incomingGoodsSelected);
};

export const canIncomingGoodBeDeleted = (incomingGood) => {
  return true;
};

export const canAllIncomingGoodsBeDeleted = (incomingGoods) => {
  if (!incomingGoods || incomingGoods.length === 0) return false;
  const selectedIncomingGoodCanBeDeleted = incomingGoods.map((x) =>
    canIncomingGoodBeDeleted(x)
  );
  return selectedIncomingGoodCanBeDeleted.every((x) => x);
};

// //#region Index columns
export const columns = [
  {
    position: 1,
    Header: "Stato",
    accessor: "status",
    filter: {
      active: true,
      name: "status",
      type: "select",
      options: incomingGoodStatus,
      multi: true,
      optionStyle: getIncomingGoodOptionStyle,
      col: 3,
    },
    Cell: ({ cell }) => {
      return getIncomingGoodStatusBadge(cell.value);
    },
  },
  {
    position: 1,
    Header: "Vettore",
    accessor: "carrierCode",
    Cell: ({ cell }) => {
      return cell.row.original.carrier?.logoUrl ? (
        <img
          title={cell.row.original.carrier.name}
          width={50}
          src={cell.row.original.carrier.logoUrl}
        />
      ) : (
        ""
      );
    },
    filter: {
      active: true,
      name: "carrierCode",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllCarriers(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: true,
      optionStyle: getCarrierOptionStyle,
      col: 3,
    },
  },
  {
    position: 2,
    Header: "Rif.Vettore",
    accessor: "carrierReference",
  },
  {
    position: 3,
    Header: "Rif.Mittente",
    accessor: "senderReference",
  },
  {
    position: 4,
    Header: "Rif.Destinatario",
    accessor: "receiverReference",
  },
  {
    position: 5,
    Header: "Magazzino",
    accessor: "warehouse.location.label",
    filter: {
      active: true,
      name: "warehouse.id",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllWarehouses(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: true,
      optionStyle: getWarehouseOptionStyle,
      col: 5,
    },
  },
  {
    position: 6,
    Header: "Data di partenza",
    accessor: "departureDate",
    sortable: true,
    filter: {
      active: true,
      name: "departureDate",
      type: "datepicker",
      col: 2,
    },
    Cell: ({ cell }) => {
      return cell.row.original.departureDate
        ? moment(cell.row.original.departureDate).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    position: 7,
    Header: "Data di arrivo previsto",
    accessor: "expectedDeliveryDate",
    Cell: ({ cell }) => {
      return cell.row.original.expectedDeliveryDate
        ? moment(cell.row.original.expectedDeliveryDate).format("DD/MM/YYYY")
        : "";
    },
  },
  {
    position: 8,
    Header: "Data di arrivo effettivo",
    accessor: "effectiveDeliveryDate",
    Cell: ({ cell }) => {
      return cell.row.original.effectiveDeliveryDate
        ? moment(cell.row.original.effectiveDeliveryDate).format("DD/MM/YYYY")
        : "";
    },
  },
];
// //#endregion

export const incomingGoodNormalizer = (incomingGood, accessTokenPayload) => {
  incomingGood.ownerId = incomingGood.ownerId || accessTokenPayload.owner;
  incomingGood.carrierCode = incomingGood.carrier?.code;
  incomingGood.warehouseId = incomingGood.warehouse?.id;
  incomingGood.expectedDeliveryDate = incomingGood.expectedDeliveryDate
    ? moment(incomingGood.expectedDeliveryDate).format("YYYY-MM-DDTHH:mm:ssZ")
    : moment().add(1, "days").startOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
  incomingGood.departureDate = incomingGood.departureDate
    ? moment(incomingGood.departureDate).format("YYYY-MM-DDTHH:mm:ssZ")
    : null;
  incomingGood.effectiveDeliveryDate = incomingGood.effectiveDeliveryDate
    ? moment(incomingGood.effectiveDeliveryDate).format("YYYY-MM-DDTHH:mm:ssZ")
    : null;
  return incomingGood;
};
