// @flow
import { all, call, fork, takeEvery } from "redux-saga/effects";

import { CHANGE_LAYOUT_MODE } from "./actionType";

/**
 * Changes the body attribute
 */
function changeHTMLAttribute(attribute, value) {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
}

/**
 * Changes the layout mode
 * @param {*} param0
 */
function* changeLayoutMode({ payload: mode }) {
  try {
    yield call(changeHTMLAttribute, "data-color-theme", mode);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Watchers
 */
export function* watchChangeLayoutMode() {
  yield takeEvery(CHANGE_LAYOUT_MODE, changeLayoutMode);
}

function* LayoutSaga() {
  yield all([fork(watchChangeLayoutMode)]);
}

export default LayoutSaga;
