import {
  getAllCarriers,
  getAllCompanies,
} from "../../utilities/asyncSelectCallAPI";
import {
  getCarrierOptionStyle,
  getCompanyOptionStyle,
} from "../../Components/common/AsyncSelect/utilities";
import moment from "moment";
import {
  contractTypes,
  getContractTypeBadge,
  getShipmentLabel,
  orderBykey,
  valueIsEmpty,
} from "../../utilities";
import { shipmentTypesList } from "../../constants";
import { Link } from "react-router-dom";
import { CarrierConfigurationsService } from "../../services/contract/carrierConfigurations";
import { dictionary } from "../../utilities/dictionary";

const carrierConfigurationsService = new CarrierConfigurationsService();

//#region Defaults
export const contractDefault = {
  name: "",
  description: "",
  type: "",
  externalReference: "",
  validityFrom: new Date(),
  validityTo: new Date(new Date().getFullYear(), 11, 31),
  shipmentType: { code: "EXPRESS", label: "Espressa" },
};
//#endregion

//#region Utilities
export const contractNormalizer = (contract, ownerId) => {
  contract.validityFrom = moment(contract.validityFrom, "DD/MM/YYYY").startOf(
    "day"
  );
  contract.validityTo = moment(contract.validityTo, "DD/MM/YYYY").startOf(
    "day"
  );
  contract.carrierConfigurationId = contract.carrierConfiguration.id;
  contract.type = contract.type.value;
  contract.shipmentType = contract.shipmentType.code;
  contract.ownerId = ownerId;
  contract.carrierCode = contract.carrierConfiguration.carrierCode;
  return contract;
};

export const contractValidation = (contract) => {
  let errors = {};
  var {
    name,
    carrierConfiguration,
    validityFrom,
    validityTo,
    type,
    shipmentType,
  } = contract;

  if (valueIsEmpty(name)) errors["name"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(carrierConfiguration))
    errors["carrierConfiguration"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(moment(validityFrom)))
    errors["validityFrom"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(moment(validityTo)))
    errors["validityTo"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(type)) errors["type"] = dictionary["nullfield"]["it"];
  if (valueIsEmpty(shipmentType))
    errors["shipmentType"] = dictionary["nullfield"]["it"];
  return errors;
};
//#endregion

//#region columns
export const columns = [
  {
    position: 3,
    Header: "Vettore",
    accessor: "carrier.name",
    filter: {
      active: true,
      name: "carrierCode",
      type: "async-select",
      loadOptions: (searchValue, pagination) => {
        return getAllCarriers(searchValue, pagination, {
          orderBy: "asc",
          selector: "name",
        });
      },
      multi: true,
      optionStyle: getCarrierOptionStyle,
      col: 3,
    },
    Cell: ({ cell }) => {
      const row = cell.row.original;
      if (row.carrierCode) {
        return <img width={50} src={row.carrier.logoUrl} />;
      }
      return "-";
    },
  },
  {
    position: 4,
    Header: "Nome",
    accessor: "name",
    sortable: true,
    filter: {
      active: true,
      name: "name",
      type: "search",
    },
  },
  {
    position: 5,
    Header: "Descrizione",
    accessor: "description",
    sortable: false,
  },
  {
    position: 6,
    Header: "Inizio validità",
    accessor: "validityFrom",
    sortable: true,
    Cell: ({ row }) => {
      const validityFrom = moment(row.values.validityFrom);
      const validityTo = moment(row.values.validityTo);
      const today = moment();

      if (today.isBefore(validityFrom) || today.isAfter(validityTo)) {
        return (
          <span className="text-danger">
            {validityFrom.format("DD/MM/YYYY")}
          </span>
        );
      }

      return validityFrom.format("DD/MM/YYYY");
    },
  },
  {
    position: 7,
    Header: "Fine validità",
    accessor: "validityTo",
    sortable: true,
    Cell: ({ row }) => {
      const validityFrom = moment(row.values.validityFrom);
      const validityTo = moment(row.values.validityTo);
      const today = moment();

      if (today.isAfter(validityTo) || today.isBefore(validityFrom)) {
        return (
          <span className="text-danger">{validityTo.format("DD/MM/YYYY")}</span>
        );
      }

      return validityTo.format("DD/MM/YYYY");
    },
  },
  {
    position: 8,
    Header: "Tipologia spedizione",
    accessor: "shipmentType",
    sortable: false,
    Cell: ({ row }) => getShipmentLabel(row.values.shipmentType),
    filter: {
      active: true,
      name: "shipmentType",
      type: "select",
      options: orderBykey(
        shipmentTypesList.map((shipmentType) => {
          return {
            value: shipmentType.code,
            label: getShipmentLabel(shipmentType.code),
          };
        }),
        "label"
      ),
      multi: false,
    },
  },
  {
    position: 9,
    Header: "Tipologia contratto",
    accessor: "type",
    sortable: false,
    Cell: ({ row }) => getContractTypeBadge(row.values.type),
    filter: {
      active: true,
      name: "type",
      type: "select",
      options: contractTypes,
      multi: false,
    },
  },
  {
    position: 10,
    Header: "Servizi",
    accessor: "services",
    sortable: false,
    Cell: ({ row }) => {
      return (
        <div className="text-center">
          <Link
            className="text-center"
            to={`/contract-services?contractId=${row.original.id}`}
          >
            {row.original.totalServices}
          </Link>
        </div>
      );
    },
  },
];
//#endregion

//#region API Calls
export const getCarrierConfiguration = (id) => {
  return new Promise((resolve, reject) => {
    carrierConfigurationsService
      .get(id)
      .then((response) => {
        resolve({
          ...response.data,
          value: response.data.id,
          label: response.data.name,
          logoUrl: response.data.carrierLogo,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#endregion
