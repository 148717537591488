import React, { useState, useEffect, useRef } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  buildQuerySort,
  buildQuerySearch,
  modalSettingsDefault,
  getUniqueSelectors,
  orderBykey,
  getClassForMultiActionElement,
  valueIsEmpty,
} from "../../utilities";
import { columns } from "./utilities";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import useFetch from "../../customHooks/useFetch";

import { Link } from "react-router-dom";
import { CreateEdit } from "./CreateEdit";
import { CreateEditOrderDetailProvider } from "./providers/CreateEdit";
import { DeleteOrderDetailProvider } from "./providers/Delete";
import Delete from "./Delete";
// import { DeleteOrderProvider } from "./providers/Delete";

const OrderDetails = ({ parentId, viewOnly = false, getData }) => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "desc",
      column: "article.name",
    },
  ]);
  const [freeSearch, setFreeSearch] = useState("");
  const [queryParams, setQueryParams] = useState([]);
  const [querySearch, setQuerySearch] = useState();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [statusOfTableRows, setStatusOfTableRows] = useState([]);

  const isSave = useRef(false);

  const modalToggle = () => {
    refreshData();
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
    setStatusOfTableRows([]);
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const handlePagination = (paginationOpt) => {
    setPagination((pagination) => {
      return { ...pagination, ...paginationOpt };
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/logistic-service/order-details?${
      parentId ? `parentId=${parentId}` : ""
    }&page=${pagination.page}&size=${pagination.size}${
      sort.length > 0 ? buildQuerySort(sort) : ""
    }${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }${freeSearch ? `&freeSearch=${freeSearch}` : ""}`
  );

  const onChangeStatusRow = (changeStatusForRows) => {
    setStatusOfTableRows((previousStatusOfTableRows) => {
      changeStatusForRows.forEach((changedRow) => {
        const existingRow = previousStatusOfTableRows.find(
          (row) => row.id === changedRow.id
        );
        if (existingRow) {
          existingRow.value = changedRow.value;
        } else {
          previousStatusOfTableRows.push(changedRow);
        }
      });
      return [...previousStatusOfTableRows];
    });
  };

  const onChangeStatusAllRows = (val) => {
    const newSelectedRow = data.content.map((x) => {
      return { id: x.id, value: val };
    });
    setStatusOfTableRows(newSelectedRow);
  };

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  useEffect(() => {
    if (isSave.current) {
      getData(data);
      isSave.current = false;
    }
  }, [data]);

  const allColumns = orderBykey(
    [
      ...columns,
      {
        Header: !viewOnly && (
          <>
            <div
              className={
                "multi-action-label scale-animation " +
                getClassForMultiActionElement(statusOfTableRows).labelClass
              }
            >
              Azioni
            </div>

            <UncontrolledDropdown
              className={
                getClassForMultiActionElement(statusOfTableRows).dropDownClass
              }
            >
              <DropdownToggle
                role="button"
                tag="button"
                type="button"
                href="#"
                className={"btn border-0 text-body"}
                data-bs-toggle="dropdown"
              >
                <i
                  className={
                    "ph-fill ph-list text-primary fw-bolder multi-action-icon scale-animation " +
                    getClassForMultiActionElement(statusOfTableRows).iconClass
                  }
                ></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" container={"body"}>
                <DropdownItem
                  href="#"
                  className={"text-danger"}
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Elimina selezionati",
                      icon: "ph-trash",
                      open: !modalSettings.open,
                      orderDetails: [
                        ...data.content
                          .filter((order) =>
                            statusOfTableRows
                              .filter((element) => element.value)
                              .map((x) => x.id)
                              .includes(order.id)
                          )
                          .map((order) => order.id),
                      ],
                      action: "delete",
                    });
                  }}
                >
                  <i className="ph-trash me-2"></i>
                  Elimina selezionati
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ),
        pinning: "right",
        accessor: "id",
        Cell: ({ row }) => {
          const { original } = row;
          return (
            !viewOnly && (
              <UncontrolledDropdown>
                <DropdownToggle
                  role="button"
                  tag="button"
                  type="button"
                  href="#"
                  className="btn border-0 text-body"
                  data-bs-toggle="dropdown"
                >
                  <i className="ph-list"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end" container={"body"}>
                  <DropdownItem
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: "Modifica dettaglio ordine",
                        icon: "ph-pencil",
                        open: !modalSettings.open,
                        action: "edit",
                        id: original.id,
                      });
                    }}
                  >
                    <i className="ph-note-pencil me-2"></i>
                    Modifica
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    href="#"
                    onClick={() => {
                      setModalSettings({
                        ...modalSettings,
                        title: "Elimina dettaglio ordine",
                        icon: "ph-trash",
                        open: !modalSettings.open,
                        action: "delete",
                        orderDetails: [original.id],
                      });
                    }}
                  >
                    <i className="ph-trash me-2"></i>
                    Elimina
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          );
        },
      },
    ],
    "position"
  );

  return (
    <React.Fragment>
      {!viewOnly && (
        <div className="row">
          <div className="col">
            <Button
              color="light"
              type="button"
              className="btn btn-outline-success btn-sm float-end"
              onClick={() => {
                setModalSettings({
                  ...modalSettings,
                  title: "Aggiungi prodotto",
                  icon: "ph-plus",
                  open: !modalSettings.open,
                  action: "create",
                });
              }}
            >
              Aggiungi prodotto <i className="ph-plus ms-2"></i>
            </Button>
          </div>
        </div>
      )}
      <TableAdvanced
        selectMultipleRows={!viewOnly}
        statusOfTableRows={statusOfTableRows}
        onChangeStatusRow={onChangeStatusRow}
        onChangeStatusAllRows={onChangeStatusAllRows}
        hasFilter={false}
        columns={allColumns}
        data={data}
        className="custom-header-css"
        tableClass={"table-xxs"}
        paginationOpt={pagination}
        onPaginationChange={handlePagination}
        sortingOpt={sort}
        onSortingChange={handleSorting}
        refreshTable={refreshData}
        onSearch={onSearch}
        showLoader={loading}
      />

      <Modal
        isOpen={modalSettings.open}
        toggle={modalToggle}
        className="modal-content"
        size={modalSettings.size || "md"}
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "create" && (
            <CreateEditOrderDetailProvider
              parentId={parentId}
              callback={() => {
                modalToggle();
                isSave.current = true;
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {modalSettings.action === "edit" && (
            <CreateEditOrderDetailProvider
              id={modalSettings.id}
              parentId={parentId}
              callback={() => {
                modalToggle();
                isSave.current = true;
              }}
            >
              <CreateEdit />
            </CreateEditOrderDetailProvider>
          )}
          {modalSettings.action === "delete" && (
            <DeleteOrderDetailProvider
              data={modalSettings.orderDetails}
              parentId={parentId}
              callback={() => {
                modalToggle();
                isSave.current = true;
              }}
            >
              <Delete />
            </DeleteOrderDetailProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderDetails;
