import React, { useEffect } from "react";
import Route from "./Routes";
import "./assets/scss/layouts/layout_1/compile/theme.scss";
import "./assets/scss/internal/utilities.scss";
import "./assets/scss/internal/variables.scss";
import "./assets/scss/styles.scss";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.classList.add("custom-scrollbars");
  }, []);

  return (
    <React.Fragment>
      <Route key={location.pathname + location.search} />
    </React.Fragment>
  );
}

export default App;
