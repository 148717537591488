import React, { useCallback, useState, useMemo, useEffect } from "react";
import AsyncSelect from "react-select/async";
import ParcelTemplatesService from "../../services/shipment-service/parcelTemplates";
import { calcSingleVolume } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import InputBox from "../../Components/common/InputBox";

const parcelTemplatesService = new ParcelTemplatesService();

export const defaultParcel = {
  width: "",
  length: "",
  height: "",
  weight: "",
  volume: "",
  quantity: 1,
  templateId: "",
  externalReference: "",
};

const Parcel = ({ item, edit, remove, parcelTypeCode }) => {
  const [parcel, setParcel] = useState(item);
  const [parcelTemplates, setParcelTemplates] = useState(item);

  const getAllParcelTemplates = (inputValue) => {
    const filters = {
      freeSearch: inputValue,
      search: {
        "parcelType.code": parcelTypeCode,
      },
    };
    return parcelTemplatesService
      .getAll(filters)
      .then((response) => {
        setParcelTemplates(response.content);
        if (inputValue) {
          return response.content;
        }
      })
      .catch((exception) => {
        toast.error(`Non ho recuperato i template colli`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue) {
      return getAllParcelTemplates(inputValue);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    edit({ ...parcel, [name]: value });
  };

  const handleSelectTemplateChange = (option, event) => {
    edit({
      ...parcel,
      [event.name]: option,
      width: option.width,
      length: option.length,
      height: option.height,
      volume: option.volume,
      weight: option.weight,
    });
  };

  useEffect(() => {
    const volume =
      calcSingleVolume(parcel.width, parcel.length, parcel.height) || "";
    setParcel((prevParcel) => ({ ...prevParcel, volume }));
  }, [parcel.width, parcel.length, parcel.height]);

  useEffect(() => {
    getAllParcelTemplates();
  }, [parcelTypeCode]);

  useEffect(() => {
    getAllParcelTemplates();
  }, []);

  useEffect(() => {
    setParcel({ ...item });
  }, [item]);

  return (
    <React.Fragment>
      <div className="row align-items-end">
        <div className="col-3">
          <label className="form-label">Template:</label>
          <AsyncSelect
            loadOptions={loadOptions}
            name="templateId"
            value={parcel.templateId || ""}
            noOptionsMessage={() => <div>Nessun template trovato</div>}
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => option["name"]}
            onChange={handleSelectTemplateChange}
            isDisabled={parcelTypeCode ? false : true}
            defaultOptions={parcelTemplates}
          />
        </div>
        <div className="col-2">
          <label className="form-label">Rif. Esterno:</label>
          <InputBox
            name="externalReference"
            value={parcel.externalReference || ""}
            placeholder={"RIFEXEMPLE001"}
            type="txt"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-1">
          <label className="form-label">Larghezza:</label>
          <InputBox
            name="width"
            value={parcel.width || ""}
            placeholder={"(cm)"}
            type="positive-number"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-1">
          <label className="form-label">Profondità:</label>
          <InputBox
            name="length"
            value={parcel.length || ""}
            placeholder={"(cm)"}
            type="positive-number"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-1">
          <label className="form-label">Altezza:</label>
          <InputBox
            name="height"
            value={parcel.height || ""}
            placeholder={"(cm)"}
            type="positive-number"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-1">
          <label className="form-label">Volume:</label>
          <InputBox
            name="volume"
            value={parcel.volume || ""}
            type="positive-number"
            disabled
            toFixed={6}
          />
        </div>
        <div className="col-1">
          <label className="form-label">Peso:</label>
          <InputBox
            name="weight"
            value={parcel.weight || ""}
            placeholder={"(kg)"}
            type="positive-number"
            onChange={handleInputChange}
            toFixed={3}
          />
        </div>
        <div className="col-1">
          <label className="form-label">Quantità:</label>
          <InputBox
            name="quantity"
            value={parcel.quantity || ""}
            placeholder={"1"}
            type="positive-integer-number"
            onChange={handleInputChange}
          />
        </div>
        <div className="col-1">
          <button
            title="Elimina"
            type="button"
            className="btn btn-outline-danger p-1"
            onClick={() => remove(parcel)}
          >
            <i className="ph-trash m-1"></i>
          </button>
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

export default Parcel;
