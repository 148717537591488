import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Spinner,
} from "reactstrap";
import { DefaultColumnFilter } from "./filters";

// Define a default UI for filtering
function GlobalFilter({
                        // preGlobalFilteredRows,
                        globalFilter,
                        setGlobalFilter,
                      }) {
  // const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm>
      <div className="search-box me-2 d-inline-block p-3">
        <div className="dataTables_length">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="me-3">
              Filter:
            </span>
            <div className="form-control-feedback form-control-feedback-end flex-fill">
              <input
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Type to filter..."}
                value={value || ""}
              />
              <div className="form-control-feedback-icon">
                <i className="ph-magnifying-glass opacity-50"></i>
              </div>
            </div>
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
                          columns,
                          data,
                          isGlobalFilter,
                          customPageSize,
                          tableClass,
                          isAddCustList,
                          isAddCustBtn,
                          isAddInitBtn,
                          isAddDropdown,
                          isAddPDFBtn,
                          isAddExcelBtn,
                          isAddPrintBtn,
                          isAddHTMLBtn,
                          isAddHTMLBtn1,
                          isAddHTMLBtn2,
                          isAddPrintBtn1,
                          handleCustomerClick,
                          theadClass,
                          thClass,
                          customPageSizeOptions,
                          showLoader,
                        }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,

    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : " ") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      {
        showLoader && <div className={"d-flex justify-content-center"}>
          <Spinner
            color="secondary"
            style={{
              height: '5rem',
              width: '5rem'
            }}
          >
            Loading...
          </Spinner>
        </div>
      }
      <div className={showLoader&&"visually-hidden"}>
        <Row className="mb-2">
          {isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
          <Col md={customPageSizeOptions ? 2 : "auto"}>
            <div className="text-sm-end p-3">
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          {isAddCustList && (
            <Col sm="7">
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="success"
                  className="btn btn-secondary mb-5 me-2 buttons-pdf buttons-html5 btn-light"
                  onClick={handleCustomerClick}
                >
                <span>
                  Export list
                  <i className="ph-file-pdf ms-2" />
                </span>
                </Button>
              </div>
            </Col>
          )}
          {isAddInitBtn && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button className="btn btn-light buttons-copy buttons-html5">
                  <span>Copy</span>
                </Button>
                <Button className="btn btn-light buttons-csv buttons-html5">
                  <span>CSV</span>
                </Button>
                <Button className="btn btn-light buttons-excel buttons-html5">
                  <span>Excel</span>
                </Button>
                <Button className="btn btn-light buttons-print buttons-html5">
                  <span>Print</span>
                </Button>
              </div>
            </Col>
          )}
          {isAddCustBtn && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="secondary"
                  className="btn-rounded me-2"
                >
                  <i className="mdi mdi-plus me-1" />
                  Custom Button
                </Button>
              </div>
            </Col>
          )}
          {isAddPDFBtn && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="light"
                  className="btn btn-rounded me-2 buttons-pdf buttons-html5"
                >
                <span>
                  Export to PDF
                  <i className="ph-file-pdf ms-2" />
                </span>
                </Button>
              </div>
            </Col>
          )}
          {isAddExcelBtn && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="light"
                  className="btn btn-rounded me-2 buttons-pdf buttons-html5"
                >
                <span>
                  Export to Excel
                  <i className="ph-file-pdf ms-2" />
                </span>
                </Button>
              </div>
            </Col>
          )}
          {isAddPrintBtn && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="secondary"
                  className="btn-rounded me-2"
                >
                  <i className="ph-printer me-2" />
                  Print table
                </Button>
              </div>
            </Col>
          )}
          {isAddPrintBtn1 && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button type="button" color="light" className="btn-rounded me-2">
                  <i className="ph-printer me-2" />
                  Print table
                </Button>
                <UncontrolledDropdown className="btn-group me-2">
                  <DropdownToggle
                    role="button"
                    tag="button"
                    type="button"
                    className="btn btn-light buttons-collection dropdown-toggle btn-primary btn-icon"
                  >
                  <span>
                    <i className="ph-list" />
                  </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Toggle First name</DropdownItem>
                    <DropdownItem>Toggle Status</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          )}
          {isAddHTMLBtn && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button type="button" color="light" className="btn-rounded me-2">
                  Excel
                </Button>
                <Button type="button" color="light" className="btn-rounded me-2">
                  PDF
                </Button>
              </div>
            </Col>
          )}
          {isAddHTMLBtn1 && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button className="btn btn-light buttons-copy buttons-html5">
                  <span>Copy</span>
                </Button>
                <Button className="btn btn-light buttons-excel buttons-html5">
                  <span>Excel</span>
                </Button>
                <Button className="btn btn-light buttons-print buttons-html5">
                  <span>PDF</span>
                </Button>
                <UncontrolledDropdown className="btn-group me-2">
                  <DropdownToggle
                    role="button"
                    tag="button"
                    type="button"
                    className="btn btn-secondary buttons-collection dropdown-toggle btn-primary btn-icon"
                  >
                  <span>
                    <i className="ph-list" />
                  </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Toggle First name</DropdownItem>
                    <DropdownItem>Toggle Status</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          )}
          {isAddHTMLBtn2 && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <Button className="btn btn-light buttons-copy buttons-html5">
                <span>
                  <i className="ph-copy me-2"></i>Copy
                </span>
                </Button>
                <Button className="btn btn-light buttons-csv buttons-html5">
                <span>
                  <i className="ph-file-csv me-2"></i>
                  CSV
                </span>
                </Button>
              </div>
            </Col>
          )}
          {isAddDropdown && (
            <Col sm="7" style={{ marginTop: "45px" }}>
              <div className="text-sm-end">
                <UncontrolledDropdown className="btn-group me-2">
                  <DropdownToggle
                    role="button"
                    tag="button"
                    type="button"
                    className="btn btn-secondary buttons-collection dropdown-toggle btn-primary btn-icon"
                  >
                  <span>
                    <i className="ph-list" />
                  </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Toggle First name</DropdownItem>
                    <DropdownItem>Toggle Status</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Col>
          )}
        </Row>

        <div className="table-responsive react-table">
          <Table hover {...getTableProps()} className={tableClass}>
            <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={thClass}
                    {...column.getSortByToggleProps()}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </th>
                ))}
              </tr>
            ))}
            </thead>

            <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
            </tbody>
          </Table>
        </div>

        <Row className="justify-content-md-end justify-content-center align-items-center p-3">
          <Col>
            <h6 className="text-mute"></h6>
          </Col>
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"Previous"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                {"Next"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
