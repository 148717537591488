import React, { useState, useEffect } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import useFetch from "../../customHooks/useFetch";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import { convertFromJson } from "fiql-query-builder";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";

import Edit from "./Edit";
import Delete from "./Delete";

// import TableContainer from "../../Components/common/TableContainer";
import CreateAfiliate from "./CreateAfiliate";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import { getUniqueSelectors, buildQuerySort } from "../../utilities";

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  id: null,
  action: "",
};

const Affiliates = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const fixedCol = [
    {
      accessor: "logoDarkUrl",
      Cell: ({ row }) => {
        if (row.original.logoDarkUrl) {
          return (
            <img
              style={{ maxWidth: "50px" }}
              src={`${row.original.logoDarkUrl}`}
              className="table-image"
              alt=""
            />
          );
        }
      },
    },

    {
      Header: "Nome",
      accessor: "name",
      sortable: true,
      filter: {
        active: true,
        name: "name",
        type: "search",
      },
    },
    {
      Header: "Riferimento esterno",
      accessor: "externalReference",
      sortable: true,
      filter: {
        active: true,
        name: "externalReference",
        type: "search",
      },
    },
    {
      Header: "PIVA",
      accessor: "vatNumber",
      sortable: false,
      filter: {
        active: true,
        name: "vatNumber",
        type: "search",
      },
    },
    {
      Header: "Azioni",
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        const { values } = row;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              <DropdownItem
                href="#"
                onClick={() => {
                  console.log(values);
                  // getUser(values.id)
                  setModalSettings({
                    ...modalSettings,
                    title: "Modifica affiliato",
                    icon: "ph-note-pencil",
                    open: !modalSettings.open,
                    action: "edit",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-note-pencil me-2"></i>
                Modifica
              </DropdownItem>
              <DropdownItem
                href="#"
                onClick={() => {
                  console.log(values);
                  // getUser(values.id)
                  setModalSettings({
                    ...modalSettings,
                    title: "Duplica Affiliato",
                    icon: "ph-users",
                    open: !modalSettings.open,
                    action: "clone",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-users me-2"></i>
                Duplica
              </DropdownItem>
              <DropdownItem href={`/addresses?companyId=${row.original.id}`}>
                <i className="ph-map-pin-line me-2"></i>I miei indirizzi
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                href="#"
                onClick={() => {
                  setModalSettings({
                    ...modalSettings,
                    title: "Elimina Affiliato",
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    action: "delete",
                    id: values.id,
                  });
                }}
              >
                <i className="ph-trash me-2"></i>
                Elimina
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  const defaultQuerySearch = [
    {
      equals: {
        selector: "type",
        args: "AFILIATE",
      },
    },
    {
      equals: {
        selector: "organization.id",
        args: accessTokenPayload.owner,
      },
    },
  ];

  const buildQuerySearch = (query = []) => {
    return encodeURIComponent(convertFromJson({ and: [...query] }));
  };

  const [queryParams, setQueryParams] = useState(defaultQuerySearch);
  const [querySearch, setQuerySearch] = useState();

  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    const uniqueSelectors = getUniqueSelectors(selector, value, preciseSearch, [
      ...queryParams,
    ]);
    handlePagination({ page: 0 });
    setQueryParams(uniqueSelectors);
  };

  const { data, loading, error, refreshData } = useFetch(
    `/registry-service/companies?page=${pagination.page}&size=${
      pagination.size
    }${sort.length > 0 ? buildQuerySort(sort) : ""}${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }`
  );

  useEffect(() => {
    setQuerySearch(buildQuerySearch(queryParams));
  }, [queryParams, querySearch, pagination, sort]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Anagrafiche" breadcrumbItem="Affiliati" />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Nuovo Affiliato",
                      icon: "ph-plus",
                      open: !modalSettings.open,
                      action: "create",
                    })
                  }
                >
                  Nuovo Affiliato <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>
            <TableAdvanced
              hasFilter={true}
              columns={fixedCol}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              onFreeSearch={onSearch}
              showLoader={loading}
            />
            <Modal
              isOpen={modalSettings.open}
              toggle={modalToggle}
              className="modal-content"
            >
              <ModalHeader toggle={modalToggle}>
                <span className="modal-title">
                  <i className={modalSettings.icon + " me-2"}></i>
                  {modalSettings.title}
                </span>
              </ModalHeader>
              <ModalBody>
                {modalSettings.action === "create" && (
                  <CreateAfiliate
                    userOwnerId={accessTokenPayload.owner}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {modalSettings.action === "edit" && (
                  <Edit
                    userOwnerId={accessTokenPayload.owner}
                    companyId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}
                {modalSettings.action === "clone" && (
                  <Edit
                    userOwnerId={accessTokenPayload.owner}
                    companyId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                    clone={true}
                  />
                )}
                {modalSettings.action === "delete" && (
                  <Delete
                    userOwnerId={accessTokenPayload.owner}
                    companyId={modalSettings.id}
                    modalToogle={modalToggle}
                    refreshTable={refreshData}
                  />
                )}

                {/* {modalSettings.userId ? (
              <Edit
                modalToogle={modalToggle}
                refreshTable={refreshData}
                userId={modalSettings.userId}
              />
            ) : (
              <Create modalToogle={modalToggle} refreshTable={refreshData} />
            )} */}
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Affiliates;
