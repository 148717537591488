import React from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import "../../assets/scss/pages/homepage.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagsShopping,
  faHandHoldingBox,
  faLongArrowRight,
  faTruckFast,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { Card } from "react-bootstrap";

import { Doughnut, Pie } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CardBody } from "reactstrap";
import Collections from "./Collections";
import { useAppContext } from "../../AppProvider";

// ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ["UK", "Italia", "Resto del mondo"],
  datasets: [
    {
      label: "# di spedizioni",
      data: [12, 19, 3],
      backgroundColor: ["#e1e506", "#91E584", "#333"],
    },
  ],
};

const Dashboard = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const appContext = useAppContext();

  return (
    <React.Fragment>
      <div className="homepage-header">
        <h1 className="title">Bentornato {accessTokenPayload.name}!</h1>
        <span className="role">{appContext?.owner?.name}</span>
        <div className="rapid-links">
          <Link to={"/shipments"} className="link">
            <FontAwesomeIcon icon={faTruckFast} size="2x" />
            <span>LE MIE SPEDIZIONI</span>
          </Link>
          <Link to={"/orders"} className="link">
            <FontAwesomeIcon icon={faBagsShopping} size="2x" />
            <span>I MIEI ORDINI</span>
          </Link>
          <Link to={"/collections"} className="link">
            <FontAwesomeIcon icon={faHandHoldingBox} size="2x" />
            <span>I MIEI RITIRI</span>
          </Link>
        </div>
      </div>
      {/* <section className="mt-4">
        <h2>Panoramica</h2>
        <div className="report-container">
          <div className="report">
            <div className="polygon"></div>
            <span className="type">TOTALE SPEDIZIONI</span>
            <span className="counter">360</span>
            <Link className="link text-end" to={"/shipments"}>
              Vedi tutti
              <FontAwesomeIcon icon={faLongArrowRight} size="md" />
            </Link>
          </div>
          <div className="report">
            <div className="polygon"></div>
            <span className="type">SPEDIZIONI BLOCCATE</span>
            <span className="counter">26</span>
            <Link className="link text-end" to={"/shipments"}>
              Vedi tutti
              <FontAwesomeIcon icon={faLongArrowRight} size="md" />
            </Link>
          </div>
          <div className="report">
            <div className="polygon"></div>
            <span className="type">ORDINI DA EVADERE</span>
            <span className="counter">13</span>
            <Link className="link text-end" to={"/orders"}>
              Vedi tutti
              <FontAwesomeIcon icon={faLongArrowRight} size="md" />
            </Link>
          </div>
          <div className="report">
            <div className="polygon"></div>
            <span className="type">ARTICOLI PRESENTI</span>
            <span className="counter">265</span>
            <Link className="link text-end" to={"/articles"}>
              Vedi tutti
              <FontAwesomeIcon icon={faLongArrowRight} />
            </Link>
          </div>
        </div>
      </section>
      <section className="mt-3">
        <h2>I miei dati</h2>
        <div className="row">
          <div className="col-4">
            <Card>
              <CardBody>
                <Doughnut data={data} style={{ width: "70%" }} />
              </CardBody>
            </Card>
          </div>
          <div className="col">
            <Card>
              <CardBody></CardBody>
            </Card>
          </div>
        </div>
      </section>
      <section className="mt-3">
        <div className="row">
          <div className="col">
            <h2>I miei ritiri</h2>
          </div>
          <div className="col text-end">
            <Link className="link text-end" to={"/collections"}>
              Vedi tutti
              <FontAwesomeIcon icon={faLongArrowRight} />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card>
              <CardBody>
                <Collections />
              </CardBody>
            </Card>
          </div>
        </div>
      </section> */}
    </React.Fragment>
  );
};

export default Dashboard;
