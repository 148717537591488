import { useEffect, useState, useCallback } from "react";
import { useOidcFetch } from "@axa-fr/react-oidc";

import * as constants from "../constants";

export default function useFetch(endpoint, opt = {}, wait = false) {
  const { fetch } = useOidcFetch();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchAsync = async (url, options) => {
    const res = await fetch(url, options);
    if (res.status !== 200) {
      return {};
    }
    return res.json();
  };

  const invokeFetch = (url = endpoint, option = opt, waitFor = wait) => {
    if (!waitFor) {
      setLoading(true);
      fetchAsync(constants.API_BASE_URL + url, option)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const refreshData = useCallback(
    (wait = false) => {
      invokeFetch(endpoint, opt, wait);
    },
    [endpoint, wait]
  );

  useEffect(() => {
    if (endpoint) invokeFetch(endpoint, opt);
  }, [endpoint, wait]);

  return { data, error, loading, refreshData };
}
