import React from "react";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

const shipmentsService = new ShipmentsService();

const DeleteAll = ({ shipments, modalToggle, refreshTable }) => {
  const deleteShipments = () => {
    let promises = [];
    let completed = 0;
    let error = 0;
    shipments.forEach((shipment) => {
      promises.push(
        shipmentsService
          .delete(shipment.id)
          .then((response) => {
            completed++;
          })
          .catch(() => {
            error++;
          })
      );
    });
    Promise.allSettled(promises).then((results) => {
      console.log(results);
      if (error) {
        if (completed === 0) {
          toast.error("Non è stato possibile cancellare le spedizioni", {
            position: toast.POSITION.TOP_CENTER,
            toastId: 1,
          });
        } else {
          toast.warning(
            "Sono state cancellate " + completed + " spedizioni su " + promises,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
      } else {
        toast.success(
          "Tutte le spedizioni sono state cancellate correttamente",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      refreshTable();
      modalToggle();
    });
  };

  return (
    <React.Fragment>
      <div className="row mb-3">
        <div className="col">
          <label>
            Sei sicuro di voler eliminare tutte le spedizioni selezionate?
          </label>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-link"
          onClick={() => {
            modalToggle();
            refreshTable();
          }}
        >
          Annulla
        </button>
        <Button className="btn btn-danger ms-3" onClick={deleteShipments}>
          Elimina
        </Button>
      </div>
    </React.Fragment>
  );
};

export default DeleteAll;
