import React, { useCallback, useState, useMemo, useEffect } from "react";
import useFetch from "../../customHooks/useFetch";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap";
import { useOidcUser } from "@axa-fr/react-oidc";
import Delete from "./delete";
import ResetPassword from "./ResetPassword";
import EnabledDisabled from "./enabledDisabled";
import {
  geTimezoneLabel,
  getCompanyBadge,
  getUniqueSelectors,
  timezones,
  stringToColor,
  buildQuerySort,
  buildQuerySearch,
} from "../../utilities";
import TableAdvanced from "../../Components/common/tableAdvanced/TableAdvanced";
import CreateEdit from "./CreateEdit";
import { CompaniesService } from "../../services/registry/companies";
import { toast } from "react-toastify";

const companiesService = new CompaniesService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  userId: null,
  action: "",
};
const defaultQuerySearch = [];
const Users = () => {
  const { oidcUser } = useOidcUser();

  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
  });
  const [sort, setSort] = useState([
    {
      orderBy: "asc",
      column: "name",
    },
  ]);
  const [queryParams, setQueryParams] = useState(defaultQuerySearch);
  const [querySearch, setQuerySearch] = useState();

  const getAllCompanies = (inputValue, pagination) => {
    const filters = {
      sorting: {
        selector: "name",
        order: "asc",
      },
      pagination,
    };
    if (inputValue) {
      filters.search = [{ selector: "name", value: inputValue }];
    }
    return companiesService
      .all(filters)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        let message = "";
        if (err.code === "ERR_NETWORK") {
          message = "Errore di rete, verifica la connessione.";
        }
        switch (err.response.status) {
          case 400:
            message = "400 - BadRequest";
            break;
          case 500:
            message = "500 - Internal server error.";
            break;
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  const handlePagination = (paginationOpt) => {
    setPagination({ ...pagination, ...paginationOpt });
  };

  const handleSorting = (sortingOpt) => {
    setSort((sort) => {
      const sortIndex = sort.findIndex((x) => x.column === sortingOpt.column);
      let newSort = [...sort];
      if (sortIndex !== -1) {
        newSort[sortIndex] = sortingOpt;
      } else {
        newSort = [];
        newSort.push(sortingOpt);
      }

      return newSort;
    });
  };

  const onSearch = (selector, value, preciseSearch = false) => {
    setQueryParams((queryParams) => {
      return getUniqueSelectors(selector, value, preciseSearch, queryParams);
    });
    handlePagination({ page: 0 });
  };

  const { data, loading, error, refreshData } = useFetch(
    `/user-service/users?page=${pagination.page}&size=${pagination.size}${
      sort.length > 0 ? buildQuerySort(sort) : ""
    }${
      queryParams.length > 0 ? `&search=${buildQuerySearch(queryParams)}` : ""
    }`
  );

  const columns = [
    {
      Header: "",
      accessor: "enabled",
      Cell: ({ row }) => {
        const values = row.values;
        return (
          <EnabledDisabled
            enabled={values.enabled}
            userId={values.id}
            refreshTable={refreshData}
          />
        );
      },
    },
    {
      Header: "Azienda",
      accessor: "owner.name",
      filter: {
        active: true,
        name: "ownerId",
        type: "async-select",
        loadOptions: (searchValue, pagination) => {
          return getAllCompanies(searchValue, pagination);
        },
        multi: true,
        optionStyle: (option) => {
          return (
            <React.Fragment>
              <img
                src={option.logoUrl}
                className="me-2"
                style={{ maxHeight: "50px", maxWidth: "50px" }}
              />
              {option.label}
              {option.externalReference && (
                <span style={{ fontSize: "10px" }}>
                  {" "}
                  - {option.externalReference}
                </span>
              )}{" "}
              {getCompanyBadge(option.type)}
            </React.Fragment>
          );
        },
        col: 5,
      },
      Cell: ({ row }) => {
        return (
          <React.Fragment>
            <label className="me-2">{row.original.owner.name}</label>
            {getCompanyBadge(row.original.owner.type)}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Nome",
      accessor: "name",
      sortable: true,
      filter: {
        active: true,
        name: "name",
        type: "search",
      },
    },

    {
      Header: "Cognome",
      accessor: "surname",
      filter: {
        active: true,
        name: "surname",
        type: "search",
      },
    },
    {
      Header: "Email",
      accessor: "email",
      filter: {
        active: true,
        name: "email",
        type: "search",
      },
    },
    {
      Header: "Timezone",
      accessor: "timeZone",
      filter: {
        active: true,
        name: "timeZone",
        type: "select",
        options: timezones,
        multi: false,
      },
      Cell: ({ cell }) => {
        if (cell.value) {
          return (
            <span
              style={{
                backgroundColor: stringToColor(cell.value),
              }}
              className="badge"
            >
              {geTimezoneLabel(cell.value).label}
            </span>
          );
        }
        return "-";
      },
    },
    {
      Header: "Azioni",
      pinning: "right",
      accessor: "id",
      Cell: ({ row }) => {
        var values = row.values;
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              role="button"
              tag="button"
              type="button"
              href="#"
              className="btn border-0 text-body"
              data-bs-toggle="dropdown"
            >
              <i className="ph-list"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" container={"body"}>
              <DropdownItem
                href="#"
                onClick={() =>
                  setModalSettings({
                    ...modalSettings,
                    title: "Modifica utente",
                    icon: "ph-plus",
                    open: !modalSettings.open,
                    userId: values.id,
                    ownerId: values.ownerId,
                    action: "edit",
                  })
                }
              >
                <i className="ph-note-pencil me-2"></i>
                Modifica
              </DropdownItem>

              <DropdownItem
                href="#"
                onClick={() =>
                  setModalSettings({
                    ...modalSettings,
                    title: "Reset password",
                    icon: "ph-lock",
                    open: !modalSettings.open,
                    userId: values.id,
                    action: "resetPassword",
                  })
                }
              >
                <i className="ph-lock me-2"></i>
                Reset Password
              </DropdownItem>
              <DropdownItem
                className="text-danger"
                href="#"
                onClick={() =>
                  setModalSettings({
                    ...modalSettings,
                    title: "Elimina utente",
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    userId: values.id,
                    action: "delete",
                  })
                }
              >
                <i className="ph-trash me-2"></i>
                Elimina
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
    },
  ];

  useEffect(() => {
    setQuerySearch(buildQuerySearch([...queryParams]));
  }, []);

  useEffect(() => {
    setQuerySearch(buildQuerySearch([...queryParams]));
  }, [queryParams, querySearch, pagination, sort]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem title="Autenticazioni" breadcrumbItem="Utenti" />
      )}

      <div className="content" id="container">
        <Card>
          <CardBody>
            <div className="row">
              <div className="col">
                {/* <Button
                  color="light"
                  type="button"
                  className="btn"
                  onClick={() => {
                    refreshData("/user-service/users?page=0&size=20&sort=desc");
                  }}
                >
                  refresh data con url modificata
                </Button>
                <Button
                  color="light"
                  type="button"
                  className="btn"
                  onClick={() => {
                    refreshData();
                  }}
                >
                  refresh data con url impostata in precedenza
                </Button> */}
                <Button
                  color="light"
                  type="button"
                  className="btn btn-outline-success btn-sm float-end"
                  onClick={() =>
                    setModalSettings({
                      ...modalSettings,
                      title: "Nuovo utente",
                      icon: "ph-plus",
                      open: !modalSettings.open,
                      action: "create",
                    })
                  }
                >
                  Nuovo utente <i className="ph-plus ms-2"></i>
                </Button>
              </div>
            </div>

            <TableAdvanced
              hasFilter={true}
              columns={columns}
              data={data}
              className="custom-header-css"
              tableClass={"table-xxs"}
              paginationOpt={pagination}
              onPaginationChange={handlePagination}
              sortingOpt={sort}
              onSortingChange={handleSorting}
              refreshTable={refreshData}
              onSearch={onSearch}
              onFreeSearch={onSearch}
              showLoader={loading}
            />
          </CardBody>
        </Card>

        <Modal
          isOpen={modalSettings.open}
          toggle={modalToggle}
          className="modal-content"
        >
          <ModalHeader toggle={modalToggle}>
            <span className="modal-title">
              <i className={modalSettings.icon + " me-2"}></i>
              {modalSettings.title}
            </span>
          </ModalHeader>
          <ModalBody>
            {modalSettings.action == "create" && (
              <CreateEdit
                ownerId={oidcUser.owner}
                callback={() => {
                  modalToggle();
                  refreshData();
                }}
              />
            )}
            {modalSettings.action == "edit" && (
              <CreateEdit
                id={modalSettings.userId}
                ownerId={modalSettings.ownerId}
                callback={() => {
                  modalToggle();
                  refreshData();
                }}
              />
            )}
            {modalSettings.action == "delete" && (
              <Delete
                userId={modalSettings.userId}
                modalToogle={modalToggle}
                refreshTable={refreshData}
              />
            )}
            {modalSettings.action == "resetPassword" && (
              <ResetPassword
                userId={modalSettings.userId}
                modalToogle={modalToggle}
                refreshTable={refreshData}
              />
            )}
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Users;
