import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { currenciesList, customDataCategories } from "../../../constants/index";
import { countries } from "../../../utilities";
import TableAdvanced from "../../../Components/common/tableAdvanced/TableAdvanced";
const CustomDataPresentation = ({ customData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const modalToggle = () => {
    setModalOpen(!modalOpen);
  };

  const columns = [
    {
      Header: "Categoria",
      accessor: "categoryCode",
      Cell: ({ cell }) => {
        const row = cell.row.original;
        return (
          <span>
            {customDataCategories.find((x) => x.code == row.categoryCode)
              .name || ""}
          </span>
        );
      },
    },
    {
      Header: "Valuta",
      accessor: "unitPriceCurrencyCode",
      Cell: ({ cell }) => {
        const row = cell.row.original;
        return (
          <span>
            {currenciesList.find((x) => x.code == row.unitPriceCurrencyCode)
              .name || ""}
          </span>
        );
      },
    },
    {
      Header: "Descrizione",
      accessor: "description",
    },
    {
      Header: "Quantità",
      accessor: "quantity",
    },
    {
      Header: "Peso unitario",
      accessor: "unitWeight",
    },
    {
      Header: "Prezzo unitario",
      accessor: "unitPrice",
    },
    {
      Header: "HS Code",
      accessor: "hsCode",
    },
    {
      Header: "Paese di origine",
      accessor: "countryIsoCode2",
      Cell: ({ cell }) => {
        const row = cell.row.original;
        const country = countries.find((x) => x.code == row.countryIsoCode2);
        return (
          <span>{country ? `${country.value} (${country.code})` : ""}</span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <label className="fw-semibold">Dati doganali:</label>{" "}
          <label>SI</label>{" "}
          <i
            className="ph-magnifying-glass cursor-pointer"
            onClick={() => setModalOpen(true)}
          />
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={modalToggle}
        className="modal-content modal-lg"
      >
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">Dati doganali</span>
        </ModalHeader>
        <ModalBody>
          <TableAdvanced columns={columns} data={customData} />
          {/* <table className={"table-xxs table table-hover"}>
            <thead>
              <tr>
                <th>Categoria</th>
                <th>Valuta</th>
                <th>Descrizione</th>
                <th>Quantità</th>
                <th>Peso unitario</th>
                <th>Prezzo unitario</th>
                <th>Codice HS</th>
                <th>Paese</th>
              </tr>
            </thead>
            <tbody>
              {console.log("customData", customData) ||
                customData.map((element) => (
                  <tr key={element.id}>
                    <td>
                      {
                        (
                          customDataCategories.find(
                            (x) => x.code == element.categoryCode
                          ) || { name: "" }
                        ).name
                      }
                    </td>
                    <td>
                      {
                        (
                          currenciesList.find(
                            (x) => x.code == element.unitPriceCurrencyCode
                          ) || { value: "" }
                        ).name
                      }
                    </td>
                    <td>{element.description}</td>
                    <td>{element.quantity}</td>
                    <td>{element.unitWeight}</td>
                    <td>{element.unitPrice}</td>
                    <td>{element.hsCode}</td>
                    <td>
                      {
                        (
                          countries.find(
                            (x) => x.code == element.countryIsoCode2
                          ) || { value: "" }
                        ).value
                      }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table> */}
        </ModalBody>
      </Modal>
      {
        //category
        //currency
        //description
        //quantity
        //unitWeight
        //unitPrice
        //hsCode
        //countryIsoCode
        //
      }
    </React.Fragment>
  );
};

export default CustomDataPresentation;
