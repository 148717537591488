import {
  CHANGE_LAYOUT_MODE
} from "./actionType";

//constants
import {
  layoutModeTypes,
} from "../../Components/constants/layout";

const INIT_STATE = {
  layoutModeType: layoutModeTypes.LIGHTMODE
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload
      };

    default:
      return state;
  }
};

export default Layout;
