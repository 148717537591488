import React, { useState } from "react";
import { AttachmentsService } from "../../services/registry/attachments";
import { Button } from "reactstrap";
import { callErrorToast } from "../../utilities";
import { Loader } from "../../Components/common/Loader";

const attachmentsService = new AttachmentsService();

const Print = ({ shipments = [], unmountCallback }) => {
  const [loader, setLoader] = useState(false);

  const print = () => {
    setLoader(true);

    const data = shipments.map((elem) => elem.id);
    console.log(data);
    attachmentsService
      .exportPDF(data)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "shipments_export_pdf_" + Date.now();
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setLoader(false);
        unmountCallback();
      })
      .catch((err) => {
        callErrorToast(err);
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      {loader && <Loader show={loader} />}
      <label>
        {shipments.length > 1
          ? `Clicca di seguito per il download delle etichette delle ${shipments.length} spedizioni (prenotate o confermate) selezionate.`
          : `Clicca di seguito per il download dell'etichetta della seguente spedizione`}
      </label>
      <div className="row mt-3">
        <div className="d-flex justify-content-end">
          <button className="btn btn-link" onClick={unmountCallback}>
            Cancella
          </button>
          <Button
            onClick={print}
            type="button"
            className="btn ms-3"
            disabled={shipments.length === 0}
          >
            Download
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Print;
