import React from "react";
import { ShipmentsService } from "../../services/shipment-service/shipments";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "reactstrap";

const shipmentsService = new ShipmentsService();

const ConfirmAll = ({ shipments, modalToggle, refreshTable }) => {
  const confirmShipments = () => {
    let promises = [];
    let completed = 0;
    let error = 0;
    shipments.forEach((shipment) => {
      promises.push(
        shipmentsService
          .confirm(shipment.id)
          .then((response) => {
            completed++;
          })
          .catch(() => {
            error++;
          })
      );
    });
    Promise.allSettled(promises).then((results) => {
      if (error) {
        if (completed === 0) {
          toast.error("Non è stato possibile confermare le spedizioni", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.warning(
            "Sono state confermate " + completed + " spedizioni su " + promises,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
      } else {
        toast.success(
          "Tutte le spedizioni sono state confermate correttamente",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }
      refreshTable();
      modalToggle();
    });
  };

  return (
    <React.Fragment>
      <form>
        <label>
          Sei sicuro di voler confermare tutte le spedizioni selezionate?
        </label>
        <div className="row mt-3">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-link"
              onClick={() => {
                modalToggle();
              }}
            >
              Annulla
            </button>
            <Button
              onClick={confirmShipments}
              type="button"
              className="btn ms-3"
            >
              Conferma
            </Button>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ConfirmAll;
